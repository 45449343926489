import '../components/Header/Header.css';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

const PageTemplate = ({ module }) => {
  const { primaryMenu, aboutUsMenu, settings } = useStaticQuery(graphql`
    query wpQueryThankYou {
      primaryMenu: wpMenu(slug: { eq: "primary-menu" }) {
        id
        name
        menuItems {
          nodes {
            id
            path
            label
            childItems {
              nodes {
                id
                path
                label
                childItems {
                  nodes {
                    id
                    path
                    label
                  }
                }
              }
            }
            parentId
          }
        }
      }
      aboutUsMenu: wpMenu(slug: { eq: "about-us-menu" }) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      settings: wp {
        fluxDnaSettings {
          fluxDna {
            email
            telephone
            headerSocialMedia {
              icon {
                altText
                mediaItemUrl
              }
              url
            }
            primaryLogo {
              altText
              mediaItemUrl
            }
          }
        }
      }
    }
  `);

  const [menuOpenStatus, setMenuOpenStatus] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="enquiry-page">
      <div className="scroll-form-main">
        <div className="enquiry-header absolute z-[9999] w-full">
          <div className="container-fluid ">
            <div className="flex flex-wrap items-center justify-between">
              <Link className="navbar-brand normal-logo" to="/">
                <img
                  src={
                    settings?.fluxDnaSettings?.fluxDna?.primaryLogo
                      ?.mediaItemUrl
                  }
                  className="max-w-[156px] laptop:max-w-[156px] laptopmini:max-w-[125px]  mdscreen:max-w-[150px] smscreen2:max-w-[100px]"
                  alt={
                    settings?.fluxDnaSettings?.fluxDna?.primaryLogo
                      ?.mediaItemUrl?.altText
                  }
                />
              </Link>
              <div className="flex gap-10">
                <Link
                  className="text-12 flex items-center cursor-pointer text-dark"
                  to="/"
                >
                  <p className="font-secondary text-17 text-red-300 capitalize pl-5 italic">
                    Back to site
                  </p>
                </Link>
                <div
                  role="presentation"
                  onClick={() => {
                    document.body.classList.toggle('nav-menu-open');
                    setMenuOpen(!menuOpen);
                  }}
                  className={`navbar-icon flex flex-wrap lgscreen:mr-0 items-center cursor-pointer justify-end hamburger menu-open-test ${
                    menuOpen ? 'active' : ''
                  }`}
                >
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="lg:w-5/12 w-full lg:order-1 order-2 mdscreen:hidden">
            <div className="ge-img">
              <img
                src="https://wp.tasafaris.com/app/uploads/2022/10/governors_balloon_safaris_1-1-scaled.jpg"
                alt="thank-you-page-image"
              />
            </div>
          </div>
          <div className="lg:w-7/12 w-full enquiry-bg h-screen overflow-y-auto lgscreen:h-auto lg:order-2 order-1 mdscreen:pb-[250px] mdscreen:min-h-screen">
            <div className="flex flex-col items-center justify-center h-full">
              <div className="lg:px-80 px-30 text-center lgscreen:pt-100 lgscreen:pb-50">
                <h3 className="lg:text-34 xlscreen:text-26 text-20">
                  Thank You For Your Enquiry!
                </h3>
                <div className="content w-[430px] m-auto px-20 lgscreen:w-full">
                  <p>
                    It will be our pleasure to assist with your Africa travel
                    plans! We find that it's best to start the planning process
                    with a call, so we will be in touch shortly.
                    <br />
                    <br />
                    Our team is based in South Africa and operates on South
                    African local time GMT+2.
                  </p>
                </div>
                <div className="btn-custom  flex flex-wrap items-center justify-center mt-30">
                  <a
                    href="https://outlook.office.com/bookwithme/user/a30eda2384cf43cea8745ac98bdd5e50%40tasafaris.com/meetingtype/29t9BZfnj0eZrHUt6MmGCw2?anonymous&isanonymous=true"
                    target="_blank"
                    className="button btn-border"
                  >
                    <span>Make an Appointment</span>{' '}
                    <img
                      src="/images/btn-red-bg.png"
                      alt="Timeless Africa Safaris image"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-nav nav-container">
          <div className="mainNavimg relative mdscreen:hidden">
            <img
              src="/images/menu-img.webp"
              className="mainNavbgImg"
              alt="Timeless Africa Safaris image"
            />
            <div className="menu-overlay absolute top-0 left-0 w-full h-full !hidden">
              <img src="/images/menu-open-bg.png" alt="menu-bg" />
            </div>
          </div>

          <div className="mainNav__wrap relative m-auto p-menu_mobile z-9 h-full">
            <div className="container-fluid h-full">
              <div className="navbarMoile w-full h-full">
                <div className="navbarMoile-inner flex items-end justify-between min-h-full mobile-menu pb-200 lgscreen:items-start lgscreen:pb-0 lgscreen:pt-100 mdscreen:pb-0 mdscreen:items-center mdscreen:flex-col mdscreen:w-full mdscreen:pr-[18vw] mdscreen:pt-0 mdscreen:justify-center mdscreen:inline-block">
                  <div className="md:w-5/12 mdscreen:w-full desktop2:w-[50%]">
                    <div className="mainNavGrid">
                      <div className="mainNav__col navMenu text-left mdscreen:text-left relative mainNav__col--left flex flex-col items-start mdscreen:items-start pr-60 mdscreen:pr-0">
                        <ul className="navMenuLeft topMenu visible list-none m-0 p-0 space-y-3 w-full">
                          {primaryMenu?.menuItems?.nodes
                            ?.filter((menuItem) => menuItem?.parentId === null)
                            .map((menuItem) => (
                              <li
                                key={menuItem.id}
                                className="sideMenuhoverable w-full"
                              >
                                <Link
                                  className="inline-block"
                                  to={menuItem.path}
                                  onMouseEnter={() => {
                                    setMenuOpenStatus(menuItem.id);
                                  }}
                                >
                                  {menuItem.label}
                                </Link>
                                {menuItem.childItems?.nodes?.length > 0 && (
                                  <span
                                    role="presentation"
                                    className={`master-menu ${
                                      menuItem.label !== 'Destinations'
                                        ? 'hidden'
                                        : ''
                                    } ${
                                      menuOpenStatus === menuItem.id
                                        ? 'menu_open_arrow'
                                        : ''
                                    }`}
                                    onClick={() => {
                                      setMenuOpenStatus((menuOpenStatus) =>
                                        menuOpenStatus === menuItem.id
                                          ? ''
                                          : menuItem.id
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faAngleRight} />
                                  </span>
                                )}
                                {menuOpenStatus === menuItem.id &&
                                  menuItem.childItems?.nodes?.length > 0 &&
                                  menuItem.label === 'Destinations' && (
                                    <div
                                      id="destination"
                                      className="p-6 mega-menu mb-16 sm:mb-0 bg-teal-700 lgscreen:!mt-10"
                                    >
                                      <div
                                        className={`mega-menu-grid flex flex-col ${
                                          menuItem.label === 'Destinations'
                                            ? 'lg:mt-[-10px]'
                                            : ''
                                        } ${
                                          menuItem.label === 'Experiences'
                                            ? 'lg:mt-[30px]'
                                            : ''
                                        } ${
                                          menuItem.label === 'Travel Styles'
                                            ? 'lg:mt-[75px]'
                                            : ''
                                        }`}
                                      >
                                        <ul className="space-y-2">
                                          {menuItem?.childItems?.nodes?.map(
                                            (childItem) => (
                                              <li key={childItem.id}>
                                                <Link to={childItem.path}>
                                                  {childItem.label}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                        <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 mt-30 lgscreen:justify-start lgscreen:mt-15 lgscreen:mb-10">
                                          <Link
                                            to={menuItem.path}
                                            className="button btn-transparent btn-transparent--mobile mdscreen:px-0"
                                          >
                                            <span className="relative z-[10] !px-0 !text-white">
                                              View All {menuItem.label}
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="btn-custom mx-0 mt-60 mdscreen:mt-30 mb-20 hidden mdscreen:block">
                    <Link
                      to="/guest-enquiry/"
                      className="button header-btn-red"
                    >
                      Let’s Start Planning
                    </Link>
                  </div>
                  <div className="md:w-5/12 w-full justify-center ipad:w-50-per mdscreen:w-full">
                    <div className="flex justify-center gap-x-20 ipad:gap-x-6 lgscreen:gap-x-10 mdscreen:justify-start mdscreen:pt-0 mdscreen:flex-col mdscreen:gap-y-8 mdscreen:pl-5">
                      {aboutUsMenu?.menuItems?.nodes?.length > 0 && (
                        <div className="mainNav__col--right">
                          <h6 className="uppercase text-19 text-black-400 pb-10 mdscreen:pb-0">
                            <Link to={aboutUsMenu?.menuItems?.nodes?.[0]?.path}>
                              {aboutUsMenu?.menuItems?.nodes?.[0]?.label}
                            </Link>
                          </h6>
                          <ul>
                            {aboutUsMenu?.menuItems?.nodes?.length > 0 &&
                              aboutUsMenu?.menuItems?.nodes
                                ?.slice(1)
                                .map((item) => (
                                  <li key={item?.id}>
                                    <Link to={item?.path}>{item?.label}</Link>
                                  </li>
                                ))}
                          </ul>
                        </div>
                      )}
                      <div className="mainNav__col--right mdscreen:pb-100">
                        <h6 className="uppercase text-19  text-black-400 pb-10 mdscreen:pb-0">
                          Contact Us
                        </h6>
                        <div className="content">
                          {settings?.fluxDnaSettings?.fluxDna?.telephone && (
                            <p>
                              <a href="tel:+27 21 461 9001">
                                T:
                                {settings?.fluxDnaSettings?.fluxDna?.telephone}
                              </a>
                            </p>
                          )}
                          {settings?.fluxDnaSettings?.fluxDna?.email && (
                            <p>
                              <a
                                href={`mailto:${settings?.fluxDnaSettings?.fluxDna?.email}`}
                              >
                                E: {settings?.fluxDnaSettings?.fluxDna?.email}
                              </a>
                            </p>
                          )}
                        </div>
                        <div className="sicon">
                          <ul className="flex mt-15 space-x-5">
                            {settings?.fluxDnaSettings?.fluxDna?.headerSocialMedia?.map(
                              (item, index) => (
                                <li key={index.toString()}>
                                  <Link to={item?.url || '#'} target="_blank">
                                    <img
                                      src={item?.icon?.mediaItemUrl}
                                      alt={item?.icon?.altText}
                                    />
                                  </Link>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                C
              </div>
            </div>

            <div className="menu-overlay absolute top-0 right-0 w-[45%] lgscreen:w-50-per ipad:w-[55%] mdscreen:w-full h-full -z-99">
              <img
                src="/images/menu-open-bg-enquiry.webp"
                loading="eager"
                alt="menu-bg"
                className="h-full object-cover w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTemplate;
export const Head = () => (
  <>
    <title>Thank You | Timeless Africa Safaris</title>
    <meta name="robots" content="noindex" />
  </>
);
